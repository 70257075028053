// // custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// // normalize CSS across browsers
// import "./src/css/normalize.css"

// // custom CSS styles
// import "./src/css/style.css"

exports.onInitialClientRender = () => {
  return window.scrollTo(0, 0)
}

exports.onServiceWorkerUpdateReady = () => {
  return window.location.reload(true)
}
